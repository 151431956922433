import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from 'components/Layout'
import Footer from 'components/Footer'
import Header from 'components/Header'

import siteMetaData from '../query/siteMetaData'

export default ({ data }) => {
  const { phone } = siteMetaData()

  const headerSources = [
    data.smallHeader.childImageSharp.fluid,
    {
      ...data.mediumHeader.childImageSharp.fluid,
      media: '(min-width: 600px)',
    },
    {
      ...data.largeHeader.childImageSharp.fluid,
      media: '(min-width: 900px)',
    },
  ]

  return (
    <Layout pageTitle='Välkommen' siteImage={data.header}>
      <Header
        fluidImage={headerSources}
        imgStyle={{ objectPosition: '50% 80%' }}
      />
      <oma-grid-row>
        <section className='section'>
          <h1 className='heading page__heading'>
            Välkommen till Stordjurs&shy;veterinärerna i Varberg!
          </h1>
          <p>
            Vi har telefontid för bokning av besök och rådgivning på vardagar
            mellan klockan 08.00-09.00.
          </p>
          <p>
            I händelse av akuta sjukdomsfall har vi jourberedskap dygnet runt,
            året runt. Våra fasta kunder prioriteras under jourtid.
          </p>
          <p>
            Ni når oss alltid på telefonnummer
            <strong>
              <oma-link to={phone} class='phone-link phone-link--large' />
            </strong>
          </p>
        </section>
        <section className='section'>
          <Img
            className='full_width_image'
            fluid={data.cow.childImageSharp.fluid}
          />
        </section>
      </oma-grid-row>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    smallHeader: file(relativePath: { eq: "duo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 599, maxHeight: 599, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mediumHeader: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, maxHeight: 600, cropFocus: WEST, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    largeHeader: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 900, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cow: file(relativePath: { eq: "cow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
